export default {
  linkCopy: {
    my: '10px',
    py: '10px',
    width: '150px',
    height: '100%',
    color: 'tmWhite',
    cursor: 'pointer',
    ':hover': {
      color: 'tmRed',
    },
  },
  linkRed: {
    my: '10px',
    py: '10px',
    width: '150px',
    height: '100%',
    color: 'tmRed',
    cursor: 'pointer',
  },
}
