export default {
  primary: {
    fontFamily: 'black',
    m: '20px',
    width: ['100vw', '540px', '712px'],
    backgroundColor: 'tmWhite',
    color: 'tmRed',
    gridColumnStart: '1',
    gridColumnEnd: '3',
    justifySelf: 'center',
    borderRadius: '0px',
  },
  saveButtonEnabled: {
    fontFamily: 'black',
    m: '20px',
    width: ['100%', '540px', '712px'],
    backgroundColor: 'tmWhite',
    color: 'tmRed',
    borderRadius: '0px',
  },
  saveButtonDisabled: {
    fontFamily: 'black',
    m: '20px',
    width: ['100%', '540px', '712px'],
    backgroundColor: 'tmGray',
    color: 'tmWhite',
    cursor: 'not-allowed',
    borderRadius: '0px',
  },
}
