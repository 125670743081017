import React from 'react'
import { Global, css } from '@emotion/core'

import DinRegular from '../assets/fonts/din-regular-webfont.ttf'
import DinLight from '../assets/fonts/din-light-webfont.ttf'
import DinBlack from '../assets/fonts/din-black-webfont.ttf'
import DinBold from '../assets/fonts/din-bold-webfont.ttf'
import DinCondensedBold from '../assets/fonts/din-condensed-bold-webfont.ttf'

const styles = css`
  @font-face {
    font-family: 'Din-Regular';
    src: url(${DinRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Din-Light';
    src: url(${DinLight}) format('truetype');
  }
  @font-face {
    font-family: 'Din-Black';
    src: url(${DinBlack}) format('truetype');
  }
  @font-face {
    font-family: 'Din-Bold';
    src: url(${DinBold}) format('truetype');
  }
  @font-face {
    font-family: 'Din-Condensed-Bold';
    src: url(${DinCondensedBold}) format('truetype');
  }
  html {
    scroll-behavior: smooth;
  }
`

const GlobalComponent = () => <Global styles={styles} />

export default GlobalComponent
