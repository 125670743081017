export default {
  root: {
    fontFamily: 'regular',
    fontSize: '16px',
    a: {
      textDecoration: 'none',
      color: '#b70d21',
    },
    p: {
      fontSize: '16px',
      fontFamily: 'light',
    },
    strong: {
      fontFamily: 'bold',
    },
  },
}
