import { rest } from 'msw'

const sampleGETRes = require('../cypress/fixtures/sample/get.json')
const geoIPGETRes = require('../cypress/fixtures/geoip/get.json')
const layoutGETRes = require('../cypress/fixtures/layout/get.json')
const formsGETRes = require('../cypress/fixtures/forms/get.json')
const contactGETRes = require('../cypress/fixtures/contact/post.json')

export const handlers = [
  rest.get(
    'http://taylormade-byob-back.green.room/api/v1/sample',
    (req, res, ctx) => {
      return res(ctx.json(sampleGETRes))
    },
  ),
  rest.get('/api/sample', (req, res, ctx) => {
    return res(ctx.json(sampleGETRes))
  }),

  rest.get('/api/geoip', (req, res, ctx) => {
    return res(ctx.json(geoIPGETRes))
  }),
  rest.get(
    'http://taylormade-byob-back.green.room/api/v1/geoip',
    (req, res, ctx) => {
      return res(ctx.json(geoIPGETRes))
    },
  ),

  rest.get('/api/layout', (req, res, ctx) => {
    return res(ctx.json(layoutGETRes))
  }),
  rest.get(
    'http://taylormade-byob-back.green.room/api/v1/layout',
    (req, res, ctx) => {
      return res(ctx.json(layoutGETRes))
    },
  ),

  rest.get('/api/forms', (req, res, ctx) => {
    return res(ctx.json(formsGETRes))
  }),
  rest.get(
    'http://taylormade-byob-back.green.room/api/v1/forms',
    (req, res, ctx) => {
      return res(ctx.json(formsGETRes))
    },
  ),

  rest.post('/api/contact', (req, res, ctx) => {
    return res(ctx.json(contactGETRes))
  }),
  rest.post(
    'http://taylormade-byob-back.green.room/api/v1/contact',
    (req, res, ctx) => {
      return res(ctx.json(contactGETRes))
    },
  ),
]
