export default {
  input: {
    ':focus': {
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      outline: 'none',
      boxShadow: '0 0 0 2px #ceecf9',
      border: '1px solid #69d0fd',
      borderRadius: '0',
    },
    color: 'tmWhite',
    padding: '1rem .75rem',
    height: '3rem',
    backgroundColor: 'tmBlack',
    border: '1px solid #ced4da',
    borderRadius: '0',
    ':-webkit-autofill': {
      backgroundColor: '#191a19 !important',
      appearance: 'none',
      '-webkit-box-shadow': '0 0 0 30px #191a19 inset !important',
      '-webkit-text-fill-color': 'white',
    },
    ':-internal-autofill-selected': {
      backgroundColor: '#191a19 !important',
      appearance: 'none',
      '-webkit-box-shadow': '0 0 0 30px #191a19 inset !important',
      '-webkit-text-fill-color': 'white',
    },
    ':-internal-autofill-previewed': {
      backgroundColor: '#191a19 !important',
      appearance: 'none',
      '-webkit-box-shadow': '0 0 0 30px #191a19 inset !important',
      '-webkit-text-fill-color': 'white',
    },
  },
  container: {
    contact: {
      px: ['10px', '0'],
      py: ['10px', '20px'],
      maxWidth: '720px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      rowGap: '25px',
      columnGap: '20px',
      button: {
        width: ['100%', '540px', '712px'],
        mx: ['10px', '0'],
      },
    },
  },
  inputContainer: {
    fontFamily: 'bold',
    color: 'tmWhite',
    fontSize: '.875rem',
    gridColumnStart: ['1', 'auto'],
    gridColumnEnd: ['3', 'auto'],
  },
  inputContainerFullWidth: {
    fontFamily: 'bold',
    color: 'tmWhite',
    fontSize: '.875rem',
  },
  selectContainer: {
    label: {
      fontFamily: 'bold',
    },
    color: 'tmWhite',
    fontSize: '.875rem',
    borderRadius: '0',
    gridColumnStart: ['1', 'inherit'],
    gridColumnEnd: ['3', 'inherit'],

    '[class$="menu"]': {
      backgroundColor: 'tmWhite',
      color: 'tmBlack',
    },
    '[class$="container"]': {
      backgroundColor: 'tmBlack',
      color: 'tmWhite',
    },
    '[class$="control"]': {
      backgroundColor: 'tmBlack',
      '[class$="singleValue"]': {
        color: 'tmWhite',
      },
    },
  },
  selectContainerFullWidth: {
    label: {
      fontFamily: 'bold',
    },
    color: 'tmWhite',
    fontSize: '.875rem',

    '[class$="menu"]': {
      backgroundColor: 'tmWhite',
      color: 'tmBlack',
    },
    '[class$="container"]': {
      backgroundColor: 'tmBlack',
      color: 'tmWhite',
    },
    '[class$="control"]': {
      backgroundColor: 'tmBlack',
      '[class$="singleValue"]': {
        color: 'tmWhite',
      },
    },
  },
  countryContainer: {
    label: {
      fontFamily: 'bold',
    },
    '[class$="menu"]': {
      backgroundColor: 'tmWhite',
      color: 'tmBlack',
    },
    '[class$="container"]': {
      backgroundColor: 'tmBlack',
      color: 'tmWhite',
    },
    '[class$="control"]': {
      backgroundColor: 'tmBlack',
      '[class$="singleValue"]': {
        color: 'tmWhite',
      },
    },
    color: 'tmWhite',
    fontSize: '.875rem',
    gridColumnStart: ['1', 'auto'],
    gridColumnEnd: ['3', 'auto'],
  },
  checkbox: {
    contact: {
      label: {
        div: {
          svg: {
            ':nth-of-type(0)': {
              color: '#495057',
            },
            ':nth-of-type(1)': {
              color: '#b70d21',
            },
          },
        },
        fontWeight: 'normal',
        a: {
          color: '#b70d21',
        },
      },
    },
  },
  checkboxContainer: {
    socialMedia: {
      display: 'none',
    },
    digitalDisplay: {
      display: 'none',
    },
    emailOptin: {
      display: 'none',
    },
  },
  multipleCheckboxesContainer: {
    '>div': {
      display: 'flex',
      maxWidth: '100%',
      flexWrap: 'wrap',
      gap: ['2px', '10px'],
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  multipleCheckboxesInput: {
    svg: {
      width: '10px',
      ':nth-of-type(2)': {
        color: 'transparent',
      },
      ':nth-of-type(1)': {
        color: 'transparent',
      },
    },
    'input:focus ~ svg': {
      color: 'transparent',
      backgroundColor: 'transparent',
    },
  },
}
