export default {
  svg: {
    default: {
      width: ['25px', '50px'],
      height: '100%',
      marginTop: '35px',
      marginBottom: '10px',
    },
    closed: {
      width: ['15px', '30px'],
      height: '100%',
      marginTop: '10px',
      marginRight: '10px',
      marginLeft: '5px',
      marginBottom: '10px',
    },
  },
  multipleCheckboxesImage: {
    borderRadius: '60px',
    width: ['109px', '90%'],
  },
}
