export default {
  title: {
    fontSize: '24px',
    fontFamily: 'bold',
    whiteSpace: 'pre-line',
    lineHeight: '1.7',
    mb: '16px',
  },
  regular: {
    fontSize: '16px',
    fontFamily: 'regular',
    whiteSpace: 'pre-line',
    lineHeight: '1.7',
    my: '16px',
  },
  light: {
    fontSize: '16px',
    fontFamily: 'light',
    whiteSpace: 'pre-line',
    lineHeight: '1.7',
    my: '16px',
  },
  bold: {
    fontSize: '16px',
    fontFamily: 'black',
    color: '#212529',
    whiteSpace: 'pre-line',
    lineHeight: '1.7',
    my: '20px',
  },
  titleAccordion: {
    fontSize: '40px',
    fontFamily: 'condensedBold',
    mt: '25px',
    mb: '10px',
    color: 'white',
  },
  cardTitle: {
    fontSize: '24px',
    color: 'tmWhite',
    fontFamily: 'condensedBold',
    fontWeight: 'bold',
    mb: '10px',
  },
  cardText: {
    fontSize: '14px',
    fontFamily: 'light',
  },
  closed: {
    fontSize: '24px',
    fontFamily: 'bold',
    whiteSpace: 'pre-line',
    lineHeight: '1.7',
    my: '16px',
  },
}
