export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: ['100%', '540px', '720px'],
    textAlign: 'center',
    pt: '20px',
  },
  accordionHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '88px',
    px: '20px',
    my: '1px',
    cursor: 'pointer',
    background: 'linear-gradient(#b70d21 50%, #850918, #640713)',

    width: ['100%', '540px', '720px'],
    ':hover': {
      backgroundColor: '#2d2d2d',
    },
  },
  accordionHeaderSubContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
    height: '100%',
    cursor: 'pointer',
    width: ['40%', '30%'],
  },
  cardContainer: {
    display: 'flex',
    alignItems: ['center', 'start'],
    textAlign: 'left',
    p: '0.8rem 0rem',
    cursor: 'pointer',
    img: {
      border: '1px solid lightGray',
    },
  },
  socialContainter: {
    display: 'flex',
  },
  cardTextContainer: {
    px: '10px',
  },
  thanksContainer: {
    display: 'flex',
    my: '3rem',
    mx: ['10px', '0'],
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: ['100%', '540px', '720px'],
    textAlign: 'center',
  },
  entryContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: ['100%', '540px', '720px'],
    pt: '20px',
  },
}
