export default {
  control: {
    color: '#495057',
    height: '3rem',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0',
    isFocused: {
      ':hover': {
        border: '1px solid #69d0fd',
      },
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
      outline: 'none',
      height: '3rem',
      boxShadow: '0 0 0 2px #ceecf9',
      border: '1px solid #69d0fd',
      borderRadius: '0',
    },
  },
  valueContainer: {
    position: 'static',
  },
  indicatorContainer: {
    position: 'relative',
    bottom: '18px',
  },
}
